
















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import { encode } from "@common/util/base64Util";
import ChargingLocationInfo from "./partials/charging-stations/ChargingLocationInfo.vue";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: { ChargingLocationInfo, BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
          "show-expand": true,
        },
        content: {
          name: "Retailers",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              name: {
                attrs: {
                  label: "Retailer",
                },
                rules: {
                  match: {},
                },
              },
              adminUserIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Admin",
                  required: true,
                  "item-value": "_id",
                  "item-text": (item) => `${item.email} (${item.username})`,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const filters = [
                          {
                            key: "_id",
                            operator: "equal_to",
                            value,
                          },
                        ];
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters,
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            name: {
              text: "Retailer",
              sortable: true,
              options : {
                to : (value, row) => {
                  const filterDefault = {
                    "customPerPage": 0,
                    "dataOptions": {
                        "groupBy": [
                        ],
                        "groupDesc": [
                        ],
                        "itemsPerPage": 50,
                        "multiSort": false,
                        "mustSort": false,
                        "page": 1,
                        "sortBy": [
                            "name"
                        ],
                        "sortDesc": [
                            false
                        ]
                    },
                    "filters": [
                        {
                            "key": "retailerId",
                            "operator": "equal_to",
                            "value": row._id
                        }
                    ]
                }
                  return 'retailer/' + row._id + '?table_options=' + encode(JSON.stringify(filterDefault));
                },
              }
            },
            adminUsers: {
              text: "User Admins",
              options: {
                labels(value) {
                  return value && value.map((item) => `${item.email} (${item.username})`);
                },
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          multiSelectActionButtons: {
            export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
              content: {
                text: "Export selected",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  coreApiClient.download("retailers", `@/export`, {
                    payload: JSON.stringify({
                      filters: [
                        {
                          key: "_id",
                          operator: "in",
                          value: dataTable.model.value.map((item) => item._id),
                        },
                      ],
                      limit: -1,
                      extraParams: {
                        currentTimeZoneOffset: new Date().getTimezoneOffset() * -1 * 60 * 1000,
                      },
                    }),
                  });
                },
              },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
              content: {
                text: "Export all",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  coreApiClient.download("retailers", `@/export`, dataTable.getFindAllOptions());
                },
              },
            },
            insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              ext: {
                condition() {
                  return appService.activeTenantValid();
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            const activeTenant = appService.getActiveTenant();
            if (activeTenant) {
              options.filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: activeTenant._id,
              });
            }
            options.extraParams.currentTimeZoneOffset = new Date().getTimezoneOffset() * -1 * 60 * 1000;
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("retailers", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("retailers", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "retailers",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("retailers", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Retailer",
                  required: true,
                }
              },
              adminUserIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Admins",
                  multiple: true,
                  "item-value": "_id",
                  "item-text": (item) => `${item.email} (${item.username})`,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const filters = [
                          {
                            key: "_id",
                            operator: "in",
                            value,
                          },
                        ];
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters,
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "adminUserIds"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "adminUserIds"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
