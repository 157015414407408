





















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";

import appService from "@/services/appService";
import centrifugeClientFactory from "@/services/centrifugeClientFactory";

export default Vue.extend({
    props: ["item", "retailerId"],
    components: {},
    data() {
        const self = this;
        return {
            self,
            tableContainer: new DataContainer(),
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "name",
                    "sort-desc": false,
                    "hide-default-footer": true,
                },
                content: {
                    urlEnabled: true,
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    displayFields: {
                        uniqueId: {
                            text: "Charge Point ID",
                            sortable: true,
                            options: {
                                attrs: {
                                    class: "font-weight-bold",
                                },
                            },
                        },
                        connectors: {
                            text: "Connectors",
                        },
                        chargerVendorBrand: {
                            text: "Model",
                            options: {
                                subProp: "chargerVendor.brand",
                            },
                        },
                        retailer: {
                            text: "Retailer",
                            options: {
                                subProp: "retailer.name",
                                label: true,
                                sortBy: "retailerId",
                            },
                        },
                        isPrivate: {
                            text: "Mode",
                            options: {
                                boolean: true,
                                label: (value: boolean) => {
                                    return value ? "Private" : "Public";
                                },
                                attrs(value) {
                                    return {
                                        color: value ? "red" : "green",
                                    };
                                },
                            },
                        },
                        chargerVendor: {
                            text: "Vendor",
                            options: {
                                subProp: "chargerVendor.name",
                                label: true,
                                sortBy: "chargerVendorId",
                            },
                        },
                        createdTime: {
                            text: "Created Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        updatedTime: {
                            text: "Updated Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
                            text: "Organization",
                            options: {
                                subProp: "tenant.name",
                                label: true,
                                sortBy: "tenantId",
                            },
                        }
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll() {
                            const filters = [
                                {
                                    key: "chargingLocationId",
                                    operator: "equal_to",
                                    value: self.item.id,
                                },
                                {
                                    key: "tenantId",
                                    operator: "equal_to",
                                    value: self.item.tenantId,
                                },
                            ];
                            if (self.retailerId) {
                                filters.push({
                                    key: "retailerId",
                                    operator: "equal_to",
                                    value: self.retailerId,
                                });
                            }
                            const { items, count } = await coreApiClient.call(
                                "chargingStations",
                                "findAll",
                                {
                                    limit: 1000,
                                    skip: 0,
                                    sort: {
                                        name: 1,
                                    },
                                    payload: JSON.stringify({
                                        limit: 1000,
                                        filters,
                                    }),
                                }
                            );
                            return [items, count];
                        },
                    },
                },
            },
        };
    },
    methods: {
        updateChargingStation(item) {
            console.log(item);
        },
    },
    created() {
        const activeTenant = appService.getActiveTenant();
        let channelName = "";

        if (activeTenant) {
            channelName = `private-chargingStations-tenantId=${activeTenant._id}`;
        } else if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                }`;
        } else if (userManager.checkRole(["retailer"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                }-retailerAdminUserId=${userManager.getUserInfo()._id}`;
        } else if (userManager.checkRole(["user"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                }-connectorItemsActiveUserId=${userManager.getUserInfo()._id}`;
        } else {
            channelName = `private-chargingStations`;
        }
        const socketId = userManager.getUserInfo()._id;
        const self = this;

        const centrifuge = centrifugeClientFactory(null, async () => {
            const {
                auth
            } = await coreApiClient.call("chargingStations", "subscribe", {
                socketId,
                channelName,
                id: self.item._id,
                connectorId : 0,
            });
            return auth;
        });
        this.centrifuge = centrifuge;
        if (channelName) {
            const sub = centrifuge.newSubscription(channelName);
            sub.on("publication", (ctx) => {
                const data = (ctx.data);
                self.updateChargingStation(data);
            });
            sub.subscribe();
            centrifuge.connect();
        }
    },
    destroyed() {
        if (this.centrifuge) {
            this.centrifuge.disconnect();
        }
    },
});
