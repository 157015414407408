















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import ChargingStationInfo from "./ChargingStationInfo.vue";

export default Vue.extend({
    props: ["retailerId", "tenantId"],
    components: { ChargingStationInfo },
    data() {
        const self = this;
        return {
            self,
            userManager,
            position: null,
            addPosition: null,
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "name",
                    "sort-desc": false,
                    "show-expand": true,
                },
                content: {
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    displayFields: {
                        name: {
                            text: "Site",
                            sortable: true,
                        },
                        address: {
                            text: "Address",
                            sortable: true,
                        },
                        description: {
                            text: "Description",
                            sortable: true,
                        },
                        createdTime: {
                            text: "Created Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        updatedTime: {
                            text: "Updated Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
                            text: "Organization",
                            options: {
                                subProp: "tenant.name",
                                label: true,
                                sortBy: "tenantId",
                            },
                        },
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll() {
                            const params = {
                                retailerId: self.retailerId,
                                tenantId: self.tenantId,
                            };
                            const locationIds = await coreApiClient.call("chargingStations", "chargingLocationByRetailer", params);
                            const { items, count } = await coreApiClient.call("chargingLocations", "findAll", {
                                payload: JSON.stringify({
                                    limit: -1,
                                    filters: [
                                        {
                                            key: "_id",
                                            operator: "in_id",
                                            value: locationIds,
                                        },
                                    ],
                                }),
                            });
                            return [items, count];
                        },
                    },
                },
            },
        };
    },
    methods: {
    },
    async created() {
    },
});
